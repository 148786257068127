export const rezorpayKey = "rzp_test_IWtrZZMm6KtqUn"; // client production key
// export const rezorpayKey = "rzp_test_SCS0aAcYYIOE6a"; //client test key
// export const baseApiUrl = "https://zip.scoremycv.com/mv1/api";
// export const mediaBaseUrl = "https://zip.scoremycv.com";

export const baseApiUrl = "http://172.16.1.213:8001/mv1/api";
export const mediaBaseUrl = "http://172.16.1.213:8001";

// export const baseApiUrl = "py-resumereboot.mobiloitte.io/mv1/api";
// export const mediaBaseUrl = "py-resumereboot.mobiloitte.io/";
